import {
  GetArticleDto,
  GetArticleByUriDto,
  FeedbackArticleDto,
  CreateTicketDto,
  UploadFileDto,
  VoteArticleDto,
  GetMyTicketsDto,
  GetFollowingArticlesDto,
  FollowDto,
  getContactCustomFieldsDto,
} from './dto';
import { createCategoryUri } from './utils';
import {
  Article,
  Category,
  ResolvedCustomField,
  Ticket,
  CountersHelper,
  Counters,
  TicketTimeline,
  TimelineReply,
  TicketAttachment,
  CompanyDisplayInfo,
  TicketSearchDto,
  ExtendedTicket,
  FileUploadResponse,
  SearchResult,
  CustomField,
  ArticleSearchDTO
} from '@wix/answers-api';
import { CategoryMatch } from '../viewer/routes/routes';
import { CategoryArticles } from '../server/rpc/types/response-dto';
import { createHttpClient } from '@wix/http-client';
export interface HelpcenterApi {
  getContactCustomFields(
    dto: getContactCustomFieldsDto,
    baseUrl?: string,
  ): Promise<ResolvedCustomField[]>;
  getCustomFields(): Promise<CustomField[]>
  getArticleById(id: string, dto: GetArticleDto): Promise<Article>;
  searchArticles(dto: ArticleSearchDTO, baseUrl?: string): Promise<{data: Article[]; itemsCount: number}>;
  getArticleByUri(
    uri: string,
    dto: GetArticleByUriDto,
    baseUrl?: string,
  ): Promise<Article>;
  getCategoryByUri(
    brandId: string,
    categoryMatch: CategoryMatch,
    baseUrl?: string,
  ): Promise<Category>;
  getArticleRelationsById(
    id: string,
    dto: GetArticleDto,
    baseUrl?: string,
  ): Promise<Article[]>;
  articleVoteForFeature(id: string, dto: FeedbackArticleDto): Promise<void>;
  articleUnvote(id: string, dto: FeedbackArticleDto): Promise<void>;
  sendArticleFeedback(id: string, dto: FeedbackArticleDto): Promise<void>;
  createTicketAsGuest(dto: CreateTicketDto): Promise<Ticket>;
  createTicketAsUser(dto: CreateTicketDto): Promise<Ticket>;
  getArticleCounters(dto: FollowDto): Promise<Counters>;
  isFollowingArticle(dto: FollowDto): Promise<boolean>;
  isFollowingCategory(dto: FollowDto): Promise<boolean>;
  followArticle(dto: FollowDto): Promise<void>;
  unfollowArticle(dto: FollowDto): Promise<void>;
  followCategory(dto: FollowDto): Promise<void>;
  unfollowCategory(dto: FollowDto): Promise<void>;
  uploadFile(
    dto: UploadFileDto,
    onUploadProgress: ({
      loaded,
      total,
    }: {
      loaded: number;
      total: number;
    }) => void,
  ): Promise<FileUploadResponse>;
  getMyTickets(dto: GetMyTicketsDto): Promise<{ items: Ticket[] }>;
  customerTicketsSearch(
    dto: TicketSearchDto,
  ): Promise<SearchResult<ExtendedTicket>>;
  getFollowingArticles(
    dto: GetFollowingArticlesDto,
  ): Promise<SearchResult<Article>>;
  getTicket(id: string): Promise<Ticket>;
  getTicketUserTimeline(id: string): Promise<TicketTimeline>;
  getTicketCustomerTimeline(id: string): Promise<TicketTimeline>;
  replyToTicket(
    ticketId: string,
    content: string,
    attachments: TicketAttachment[],
    ccUserIds?: string[],
  ): Promise<TimelineReply>;
  getCategoryTopArticles(
    dto: {
      categoryIds: string[];
      locale: string;
      limit?: number;
    },
    baseUrl?: string,
  ): Promise<CategoryArticles[]>;
  getMyComapnies(): Promise<CompanyDisplayInfo[]>;
}

const apiUrl = '/api/v1';
export const httpClient = createHttpClient();
export const httpClientPost = httpClient.post.bind(httpClient);

export const helpcenterApi: HelpcenterApi = {
  getContactCustomFields: async (dto: getContactCustomFieldsDto, baseUrl: string) => {
    const path = `${apiUrl}/helpcenter/formFields/${dto.params.locale}`;
    const response = await httpClient.get<any>(path, dto);
    return response.data;
  },
  getCustomFields: () => {
    return httpClient.get<any>(`${apiUrl}/fields/`).then(
      (response) => response.data,
    );
  },
  getArticleById: (id: string, dto: GetArticleDto) => {
    return httpClient.get<any>(`${apiUrl}/articles/${id}`, dto).then(
      (response) => response.data,
    );
  },
  getMyTickets: (params) => {
    return httpClient.post<any>(`${apiUrl}/tickets/my`, params).then(
      (response) => response.data,
    );
  },
  customerTicketsSearch: (params) => {
    return httpClient.post<any>(`${apiUrl}/tickets/search/customer`, params).then(
      (response) => response.data,
    );
  },
  getTicket: (id: string) => {
    return httpClient.get<any>(`${apiUrl}/tickets/${id}`).then(
      (response) => response.data,
    );
  },
  getTicketUserTimeline: (id: string) => {
    return httpClient.get<any>(`${apiUrl}/tickets/${id}/userTimeline`).then(
      (response) => response.data,
    );
  },
  getTicketCustomerTimeline: (id: string) => {
    return httpClient.get<any>(`${apiUrl}/tickets/${id}/customerTimeline`).then(
      (response) => response.data,
    );
  },
  replyToTicket: (ticketId, content, attachments, ccUserIds) => {
    return httpClient.post<any>(`${apiUrl}/tickets/${ticketId}/replies`, {
      ticketId,
      content,
      makeTicketPrivate: false,
      attachments,
      ccUserIds,
    }).then((response) => response.data);
  },
  getFollowingArticles: (params) => {
    return httpClient.get<any>(`${apiUrl}/articles/followed`, { params }).then(
      (response) => response.data,
    );
  },
  searchArticles: (dto: ArticleSearchDTO, baseUrl: string) => {
    const path = baseUrl
      ? `${baseUrl}/${apiUrl}/helpcenter/articles/search`
      : `${apiUrl}/helpcenter/articles/search`;
    return httpClient.post<any>(path, dto).then((res) => ({
      data: res.data.items,
      itemsCount: res.data.itemsCount,
    }));
  },
  getArticleByUri: (uri: string, dto: GetArticleByUriDto, baseUrl: string) => {
    const path = baseUrl
      ? `${baseUrl}/${apiUrl}/articles/uri/${uri}`
      : `${apiUrl}/articles/uri/${uri}`;
    return httpClient.get<any>(path, dto).then((res) => res.data);
  },
  getArticleRelationsById: (
    id: string,
    dto: GetArticleDto,
    baseUrl: string,
  ) => {
    const path = baseUrl
      ? `${baseUrl}/${apiUrl}/helpcenter/articles/${id}/relations`
      : `${apiUrl}/helpcenter/articles/${id}/relations`;
    return httpClient.get<any>(path, dto).then((response) => response.data);
  },
  getCategoryByUri: (
      brandId: string,
      categoryMatch: CategoryMatch,
      baseUrl: string
    ) => {
    const uri = createCategoryUri(categoryMatch);

    const params = { locale: categoryMatch.locale, brandId };
    const path = baseUrl
      ? `${baseUrl}/${apiUrl}/categories/uri/${uri}`
      : `${apiUrl}/categories/uri/${uri}`;
    return httpClient.get<any>(path, { params }).then((res) => res.data);
  },
  getCategoryTopArticles: async (dto) => {
    const path = `${apiUrl}/helpcenter/articles/categories/top/optimized`;
    const res = await httpClient.post<any>(path, dto);
    return res.data;
  },
  articleVoteForFeature: (id: string, dto: VoteArticleDto) => {
    return httpClient.post<any>(`${apiUrl}/articles/${id}/vote`, dto).then();
  },
  articleUnvote: (id: string, dto: VoteArticleDto) => {
    return httpClient.delete(
      `${apiUrl}/articles/${id}/vote?locale=${dto.locale}`,
    ).then();
  },
  sendArticleFeedback: (id: string, dto: FeedbackArticleDto) => {
    return httpClient.post<any>(`${apiUrl}/articles/${id}/helpful`, dto).then();
  },
  createTicketAsGuest: (dto: CreateTicketDto) => {
    return httpClient.post<any>(`${apiUrl}/internal/tickets/guest`, dto).then((res) => res.data);
  },
  createTicketAsUser: (dto: CreateTicketDto) => {
    return httpClient.post<any>(`${apiUrl}/tickets`, dto).then((res) => res.data);
  },
  getArticleCounters: (dto) =>
    httpClient.get<any>(
      `${apiUrl}/articles/${dto.id}/counters?locale=${dto.locale}`,
    ).then((res) => CountersHelper.convertCounterResultsToCounters(res.data)),
  isFollowingArticle: (dto) =>
    httpClient.get<any>(`${apiUrl}/articles/${dto.id}/follow?locale=${dto.locale}`).then(
      (res) => res.data,
    ),
  isFollowingCategory: (dto) =>
    httpClient.get<any>(
      `${apiUrl}/categories/${dto.id}/follow?locale=${dto.locale}`,
    ).then((res) => res.data),
  followArticle: (dto) =>
    httpClient.post<any>(`${apiUrl}/articles/${dto.id}/follow`, dto).then(
      (res) => res.data,
    ),
  unfollowArticle: (dto) =>
    httpClient.delete<any>(
      `${apiUrl}/articles/${dto.id}/follow?locale=${dto.locale}`,
    ).then((res) => res.data),
  followCategory: (dto) =>
    httpClient.post<any>(`${apiUrl}/categories/${dto.id}/follow`, dto).then(
      (res) => res.data,
    ),
  unfollowCategory: (dto) =>
    httpClient.delete<any>(
      `${apiUrl}/categories/${dto.id}/follow?locale=${dto.locale}`,
    ).then((res) => {
      return res.data;
    }),
  uploadFile: (
    dto: UploadFileDto,
    onUploadProgress: ({
      loaded,
      total,
    }: {
      loaded: number;
      total: number;
    }) => void,
  ) => {
    return httpClient.post<any>(`${apiUrl}/files/base64`, dto, {
      onUploadProgress,
    }).then((response) => response.data);
  },
  getMyComapnies: () =>
    httpClient.get<any>(`${apiUrl}/companies/my`).then((res) => res.data),
};
