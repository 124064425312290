import * as React from 'react';
import { ContactFormComp } from './contact-form';
import { ResolvedCustomField, ContactFormSectionData, MultilevelCustomFieldValueBuilder, MultilevelCustomFieldBuilder, MultilevelCustomField, CustomFieldLocaleData } from '@wix/answers-api';
import { produce } from 'immer';
import { TenantInfo } from '../../../common/tenant-info.i';
import { withExperiments } from '@wix/wix-experiments-react';
import { BiLogger } from '../../routes/bi';

const resolveEnabledFields = (
  customFields: ResolvedCustomField[],
  config: ContactFormSectionData['config'],
  tenant: TenantInfo,
): ResolvedCustomField[] => {
  if (config.customFieldsEnabled) {
    const resolved: ResolvedCustomField[] = [];

    customFields.forEach((field) => {
      const foundFieldConfig = config.customFields.find(
        ({ customFieldId }) => customFieldId === field.id,
      );
      if (foundFieldConfig) {
        // overrides server resolved field configuration with section config
        resolved.push(
          produce(field, (draft) => {
            draft.mandatory = foundFieldConfig.mandatory;
          }),
        );
      }
    });
    return resolved;
  }
  return [];
  
};

export const ContactFormWrapper = (factoryProps: {
  customFields: ResolvedCustomField[];
  config: ContactFormSectionData['config'];
  biLogger: BiLogger;
  tenant: TenantInfo;
  model;
}) =>
  withExperiments((props) => {
    const { customFields, config, biLogger, tenant, model } = factoryProps;

    const resolvedEnabledFields = resolveEnabledFields(
      customFields,
      config,
      tenant,
    );

    return (
      <div>
        <ContactFormComp
          value=""
          onChange={() => null}
          customFields={resolvedEnabledFields}
          biLogger={biLogger}
          tenant={tenant} 
          user={model.sessionUser}
          enableEmailConfirmation={props.experiments.enabled(
            'specs.HelpcenterContactFormEmailConfirmation',
          )}
        />
      </div>
    );
  });
