import * as React from 'react';
import { Helmet } from 'react-helmet';
import { renderRoutes } from 'react-router-config';
import {
  SectionBlueprints,
  SectionRenderedBlueprints,
  pageTypeMap,
  ComponentTypes,
} from '../common/helpcenter-types';
import {
  resolveAllTemplates,
  templateCreator,
} from './section-rendering/compile-time/template-creator';
import { HelpcenterData } from '../helpcenter-data';
import { getRoutes } from './routes/routes';
import './style.scss';
import { classNames } from '@wix/answers-lib';
import {
  HelpcenterStructure,
  HelpcenterBrandingSettings,
  PageStructure,
  HelpcenterPageType,
  ResolvedCustomField,
  HelpcenterSection,
} from '@wix/answers-api';
import { produce } from 'immer';
import LoadingBar from 'react-top-loading-bar';
import {
  ViewerSdk,
  NavigateEventPayload,
} from '@wix/answers-helpcenter-preview-sdk';
import { HelpcenterContext } from './helpcenter-context';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ComponentEventPayload } from '@wix/answers-helpcenter-preview-sdk/dist/types/common/component-event';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';
import { hasContactPage } from '../common/contact-utils';
import { GetCompiledTemplatePayload } from '@wix/answers-helpcenter-preview-sdk/dist/types/common/editor-events';
import * as keyBy from 'lodash.keyby';
import { HelpcenterComponent } from '../server/style-resolver/utils';
import { seoManager } from './utils/seo-manager';
import './utils/sdk/self-exec';
import * as moment from 'moment';
import { PREVIEW_TICKET_ID } from './preview-types'
import { encodeClientSearchTerm } from './components-for-sections/search-input/search-input';
import Axios from 'axios';

type ViewerProps = {
  initialData: HelpcenterData;
  templates: SectionRenderedBlueprints;
  helpcenterStructure: HelpcenterStructure;
  editorMode?: boolean;
  baseModel: { baseStaticsUrl: string };
  appConfig: AppConfig;
  branding: HelpcenterBrandingSettings;
  modifiedModel?: any;
} & WithTranslation &
  InjectedExperimentsProps;

export interface AppRenderRoutesAdditionalProps {
  initialData: HelpcenterData;
  templates: SectionRenderedBlueprints;
  baseModel: { baseStaticsUrl: string };
}

export interface AppConfig {
  preview: boolean;
}

const Routes = (data: ViewerProps) => {
  const {
    initialData,
    templates,
    helpcenterStructure,
    baseModel,
    appConfig,
    experiments,
    branding,
    modifiedModel = {},
  } = data;

  const isCustomerPortalEnabled = experiments.enabled('specs.EnableCustomerPortal');

  const isHelpcenterEnabled = !initialData.tenant || initialData.tenant.helpCenterSettings.enabled || appConfig.preview;
  const isTicketingEnabled = !initialData.tenant || initialData.tenant.helpCenterSettings.ticketsPageEnabled || appConfig.preview;

  const routes = getRoutes({
    // tslint:disable-next-line: no-non-null-assertion
    enableContactPage: hasContactPage(initialData.tenant!) || appConfig.preview,
    enableCustomerPortal: isCustomerPortalEnabled,
    isHelpcenterEnabled: isHelpcenterEnabled || (isCustomerPortalEnabled ? !!initialData.isAgent && !isTicketingEnabled : !!initialData.isAgent),
    isTicketingEnabled: isTicketingEnabled || (!!initialData.isAgent && !isHelpcenterEnabled),
    premiumData: initialData.premium,
  });

  return React.useMemo(() => renderRoutes(
    routes,
    {
      templates,
      initialData,
      helpcenterStructure,
      baseModel,
      appConfig,
      modifiedModel,
      branding,
    },
  ), [
    templates,
    initialData,
    helpcenterStructure,
    baseModel,
    appConfig,
    modifiedModel,
    branding,
  ]);
}
class _Viewer extends React.Component<ViewerProps> {
	context!: React.ContextType<typeof HelpcenterContext>;
  static contextType = HelpcenterContext;

  render() {
    const {
      initialData,
      templates,
      helpcenterStructure,
      editorMode,
      baseModel,
      appConfig,
      experiments,
      branding,
      modifiedModel = {},
    } = this.props;
    if (initialData?.tenant) {
      seoManager.setTenant(initialData.tenant);
    }

    return (
      <div
        className={classNames('root', {
          'show-override-indicator': editorMode,
        })}
      >
        <Helmet>
          <title>{initialData?.tenant?.brandName}</title>
          <meta name='og:title' content={this.props.t('app.page.title', {
            name: initialData?.tenant?.brandName,
          })} />
        </Helmet>
        <Routes {...this.props} />
      </div>
    );
  }
}

export const Viewer = withExperiments(withTranslation()(_Viewer));

interface AppProps extends RouteComponentProps {
  helpcenterStructure: HelpcenterStructure;
  templates: SectionRenderedBlueprints;
  initialData: HelpcenterData;
  pageInitialData?: any;
  blueprints: SectionBlueprints;
  branding: HelpcenterBrandingSettings;
  editorMode?: boolean;
  baseModel: { baseStaticsUrl: string; baseURL: string };
}

interface AppState {
  helpcenterStructure: HelpcenterStructure;
  templates: SectionRenderedBlueprints;
  modifiedStyles?: string;
  previewError: boolean;
  branding: HelpcenterBrandingSettings;
  previewMode: boolean;
  modifiedModel: any;
}

class _App extends React.Component<AppProps, AppState> {
  previewSdk!: ViewerSdk;
  loadingBar: React.RefObject<unknown>;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      templates: props.templates,
      helpcenterStructure: props.helpcenterStructure,
      branding: props.branding,
      previewError: false,
      modifiedStyles: document.getElementById('modified-styles')?.innerText,
      previewMode: false,
      modifiedModel: {},
    };
    this.loadingBar = React.createRef();
  }

  componentDidMount() {
    this.setupPreviewSdk();
    this.setGlobalMomentLocale();
  }
  setGlobalMomentLocale() {
    const currentLocale = this.props.initialData.tenant.currentLocale;
    if (currentLocale !== 'en') {
      return import(`moment/locale/${currentLocale}`)
        .then(() => moment.locale(currentLocale))
        .catch((e) => {
          moment.locale('en');
          console.warn(e);
        });
    }
  }
  setupPreviewSdk() {
    this.previewSdk = new ViewerSdk({
      shouldLog: true,
      origin: false,
      onReady: () => this.setState({ previewMode: true }),
    });

    const handleUpdateBranding = async (
      branding: HelpcenterBrandingSettings,
    ) => {
      const {
        data: { styles },
      } = await Axios.post<any>(`${this.props.baseModel.baseURL}/preview`, {
        structure: this.state.helpcenterStructure,
        branding,
      });

      await new Promise<void>((resolve) =>
        this.setState(
          {
            modifiedStyles: styles,
            branding,
          },
          resolve,
        ),
      );
    };

    const handleUpdatePage = async (pageStructure: PageStructure<any>) => {
      const { helpcenterStructure: newStructure } = produce(
        { helpcenterStructure: this.state.helpcenterStructure },
        ({ helpcenterStructure }) => {
          helpcenterStructure.pages[
            pageTypeMap[pageStructure.type]
          ] = pageStructure;
        },
      );
      try {
        const { data } = await Axios.post<any>(
          `${this.props.baseModel.baseURL}/preview`,
          {
            structure: newStructure,
            branding: this.state.branding,
          },
        );
        if (!data.success) {
          throw new Error(data.error);
        }

        await new Promise<void>((resolve) =>
          this.setState(
            {
              modifiedStyles: data.styles,
              helpcenterStructure: newStructure,
            },
            resolve,
          ),
        );
      } catch (err: any) {
        if (err.data) {
          return Promise.reject(err.data.error);
        }
        return Promise.reject(err);
      }
    };

    const handleUpdateComponent = async (event: ComponentEventPayload) => {
      const { helpcenterStructure: newStructure } = produce(
        { helpcenterStructure: this.state.helpcenterStructure },
        ({ helpcenterStructure }) => {
          switch (event.type) {
            case ComponentTypes.Header:
              helpcenterStructure.header = event.data;
              break;
            case ComponentTypes.Footer:
              helpcenterStructure.footer = event.data;
              break;
            case ComponentTypes.NavSidebar:
              helpcenterStructure.sidebar = event.data;
              break;
            default:
          }
        },
      );
      const {
        data: { styles },
      } = await Axios.post<any>(`${this.props.baseModel.baseURL}/preview`, {
        structure: newStructure,
        branding: this.state.branding,
      });

      await new Promise<void>((resolve) =>
        this.setState(
          {
            modifiedStyles: styles,
            helpcenterStructure: newStructure,
          },
          resolve,
        ),
      );
    };

    const handleUpdateCustomFields = async (
      contactFormFields: ResolvedCustomField[],
    ) => {
      await new Promise<void>((resolve) =>
        this.setState(
          {
            modifiedModel: { contactFormFields },
          },
          resolve,
        ),
      );
    };

    const handleSDKNavigation = async (payload: NavigateEventPayload) => {
      const { history } = this.props;
      switch (payload.page) {
        case HelpcenterPageType.ARTICLE:
        case HelpcenterPageType.ARTICLE_KNOWN_ISSUE:
        case HelpcenterPageType.CATEGORY:
        case HelpcenterPageType.SUB_CATEGORIES:
        case HelpcenterPageType.ARTICLE_FEATURE_REQUEST:
          history.push(`/${payload.locale}${payload.uri}`);
          break;
        case HelpcenterPageType.CATEGORIES:
          history.push(`/${payload.locale}/categories`);
          break;
        case HelpcenterPageType.CONTACT:
          history.push(`/${payload.locale}/contact`);
          break;
        case HelpcenterPageType.ERROR:
        case HelpcenterPageType.HOMEPAGE:
          history.push(`/${payload.locale}`);
          break;
        case HelpcenterPageType.REQUEST_LIST:
          history.push(`/${payload.locale}/account?preview`);
          break;
        case HelpcenterPageType.REQUEST_PAGE:
          history.push(`/${payload.locale}/ticket/${PREVIEW_TICKET_ID}`);
          break;
        case HelpcenterPageType.FOLLOWED_ARTICLES:
          history.push(`/${payload.locale}/articles?preview`);
          break;
        case HelpcenterPageType.SEARCH_RESULTS:
          history.push(`/${payload.locale}/search?term=${encodeClientSearchTerm(payload.searchTerm)}`);
          break;
        default:
          throw new Error(`invalid page`);
      }
    };

    const loaderWrapper = <T extends unknown | void>(
      func: (...args: any) => Promise<T | void>,
    ): ((...args: any) => Promise<T | void>) => {
      return (...args) => {
        (this.loadingBar as any)?.continuousStart();
        return func(...args)
          .then((res) => {
            (this.loadingBar as any)?.complete();
            return res;
          })
          .catch((err) => {
            this.setState({ previewError: true }, () =>
              (this.loadingBar as any)?.complete(),
            );
            throw err;
          });
      };
    };

    const handleGetCompiledTemplate = async (
      payload: GetCompiledTemplatePayload,
    ) => {
      const sectionsById: Record<
        string,
        HelpcenterSection | HelpcenterComponent
      > = Object.values(this.state.helpcenterStructure.pages).reduce(
        (acc, page) => ({ ...acc, ...keyBy(page.sections, 'id') }),
        {},
      );
      sectionsById.header = {
        ...this.state.helpcenterStructure.header,
        type: ComponentTypes.Header,
      };
      sectionsById.footer = {
        ...this.state.helpcenterStructure.footer,
        type: ComponentTypes.Footer,
      };

      const section = sectionsById[payload.sectionId];
      if (!section) {
        throw new Error('section not found');
      }

      const compiledBlueprint = templateCreator(
        section,
        this.props.blueprints,
        this.state.branding,
      );
      return Promise.resolve(compiledBlueprint.template);
    };

    this.previewSdk.onBrandingUpdate(loaderWrapper(handleUpdateBranding));
    this.previewSdk.onPageUpdate(loaderWrapper(handleUpdatePage));
    this.previewSdk.onNavigate(loaderWrapper(handleSDKNavigation));
    this.previewSdk.onComponentUpdate(loaderWrapper(handleUpdateComponent));
    this.previewSdk.onUpdateCustomFields(
      loaderWrapper(handleUpdateCustomFields),
    );
    this.previewSdk.onGetCompiledTemplate(
      loaderWrapper(handleGetCompiledTemplate),
    );
  }

  resetPreviewLoader() {
    this.setState({ previewError: false });
  }

  render() {
    return (
      <>
        <Viewer
          initialData={this.props.initialData}
          templates={resolveAllTemplates(
            this.state.helpcenterStructure,
            this.props.blueprints,
            this.state.branding,
          )}
          baseModel={this.props.baseModel}
          helpcenterStructure={this.state.helpcenterStructure}
          editorMode={this.props.editorMode}
          appConfig={{ preview: this.state.previewMode }}
          modifiedModel={this.state.modifiedModel}
          branding={this.state.branding}
        />
        <LoadingBar
          height={3}
          color={
            this.state.previewError ? 'red' : this.state.branding.actionColor
          }
          onRef={(ref: any) => (this.loadingBar = ref)}
          onLoaderFinished={() => this.resetPreviewLoader()}
        />
        <Helmet>
          {this.state.modifiedStyles && (
            <style id="modified-styles">{this.state.modifiedStyles}</style>
          )}
        </Helmet>
      </>
    );
  }
}

export const App = withRouter(_App);
