import * as React from 'react';
import {hydrate} from 'react-dom';
import {AnalyticsLogger, SourceType, HelpcenterBrandingSettings} from '@wix/answers-api';
import { useSSR } from 'react-i18next';
import i18n from './common/i18n/i18n';
import { App } from './viewer';
import { BrowserRouter } from 'react-router-dom';
import { HelpcenterContext } from './viewer/helpcenter-context';
import fedopsLogger from './viewer/utils/fedops-logger';
import { SectionBlueprints } from './common/helpcenter-types';
import { ScrollToTop } from './viewer/utils/scroll-to-top';
import { getApiUrl, isIeByUa } from './common/utils';
import { SpinnerLoader } from '@wix/answers-ui-libby';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { enableES5 } from "immer"
import { HelpcenterData } from './helpcenter-data';
import { httpClientPost } from './api';
import axios from 'axios';
import { wixAxiosConfig } from '@wix/wix-axios-config';

enableES5();

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
  whyDidYouRender(React);
  const io = require('socket.io-client');

  // naive hot reload
  const socket = io('http://localhost:4200');
  socket.on('client_file_changed', () => {
    window.location.reload();
  });
}

const baseURL = window.__BASEURL__;
const baseStaticsUrl = window.__BASE_STATICS_URL__;
const locale = window.__LOCALE__;
const translationOverrides = window.__TRANSLATION_OVERRIDES__;
const initialI18nStore = window.__TRANSLATION_STORE__;
const initialData: HelpcenterData = window.__INITIAL_DATA__;
const pageInitialData = window.__PAGE_INITIAL_DATA__;
const templates = window.__TEMPLATES__;
const blueprints = window.__BLUEPRINTS__;
const helpcenterStructure = window.__STRUCTURE__;
const branding: HelpcenterBrandingSettings = window.__BRANDING__;
const editorMode = window.__EDITORMODE__;
const captchaKey = window.__CAPTCHA_KEY__;
const reCaptchaV3Key = window.__CAPTCHA_KEY_V3__;

wixAxiosConfig(axios, { baseURL: '/' });
i18n(locale, translationOverrides);
fedopsLogger.appLoadingPhaseStart('startingHydration');

if (isIeByUa(navigator?.userAgent)) {
  try {
    window.onpopstate = () => location.reload();
  } catch (e) { console.error(e) }
}

const BaseApp = () => {
  useSSR(initialI18nStore, locale);

  const routeBaseUrl = baseURL.replace(/^\//, '');

  return (
    <HelpcenterContext.Provider
      value={{
        ...initialData,
        pageModelData: pageInitialData,
        locale,
        captchaKey,
        reCaptchaV3Key
      }}
    >
      <ExperimentsProvider options={{ experiments: window.__ansExperiments }}>
        <BrowserRouter basename={routeBaseUrl} forceRefresh={isIeByUa(navigator?.userAgent)}>
          <React.Suspense fallback={<div><SpinnerLoader color={branding.actionColor} /></div>}>
            <ScrollToTop />
            <App
              initialData={initialData}
              pageInitialData={pageInitialData}
              templates={templates}
              blueprints={blueprints as SectionBlueprints}
              helpcenterStructure={helpcenterStructure}
              editorMode={editorMode}
              baseModel={{ baseStaticsUrl, baseURL: routeBaseUrl }}
              branding={branding}
            />
          </React.Suspense>
        </BrowserRouter>
      </ExperimentsProvider>
    </HelpcenterContext.Provider>
  );
};

hydrate(<BaseApp />, document.getElementById('root'), () => {
  fedopsLogger.appLoaded();
});

AnalyticsLogger.sessionStart(
  httpClientPost,
  getApiUrl,
  locale,
  SourceType.HELP_CENTER,
  null,
);
